export const orderIndexSorter = function (a: GalleryItem, b: GalleryItem) {
  return a.orderIndex > b.orderIndex ? 1 : -1;
};

export const getDateCreated = function (
  galleryItem: GalleryItem,
  coverIdToAlbum?: { [id: string]: GogAlbum },
) {
  const date = coverIdToAlbum
    ? coverIdToAlbum[galleryItem.itemId]?.dateCreated
    : galleryItem.dateCreated || '';
  return new Date(date).getTime();
};

export const sortByEnum = {
  MANUALLY: 0,
  OLDEST_FIRST: 1,
  NEWEST_FIRST: 2,
};

export const albumDateCreatedSorter = function (
  coverIdToAlbum?: { [id: string]: GogAlbum },
  newestFirst: boolean = true,
) {
  return (a: GalleryItem, b: GalleryItem) => {
    const aDateCreated = getDateCreated(a, coverIdToAlbum);
    const bDateCreated = getDateCreated(b, coverIdToAlbum);
    return newestFirst
      ? bDateCreated > aDateCreated
        ? 1
        : -1
      : aDateCreated > bDateCreated
      ? 1
      : -1;
  };
};

export const getSortByFunc = function (
  sortByKey: number,
  coverIdToAlbum?: { [id: string]: GogAlbum },
) {
  switch (sortByKey) {
    case sortByEnum.MANUALLY:
      return orderIndexSorter;
    case sortByEnum.OLDEST_FIRST:
      return albumDateCreatedSorter(coverIdToAlbum, false);
    case sortByEnum.NEWEST_FIRST:
      return albumDateCreatedSorter(coverIdToAlbum);
    default:
      return orderIndexSorter;
  }
};

export const getSortedAlbums = function (
  sortByKey: number,
  albumsCovers: GalleryItem[],
  coverIdToAlbum: {
    [id: string]: GogAlbum;
  },
) {
  return albumsCovers.sort(getSortByFunc(sortByKey, coverIdToAlbum));
};
