import { InitAppForPageFn } from '@wix/yoshi-flow-editor';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowAPI,
) => {
  const { reportError } = flowAPI;
  try {
    console.log('App inited');
  } catch (e: any) {
    reportError(e);
  }
};
