export const getQueryParamByName = (
  name: string,
  url = window.location.href,
) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const manageWarmupData = async (
  callback: any,
  key: string,
  warmupData: any,
  isSSR: boolean,
  compId: string,
) => {
  let res;
  if (!isSSR) {
    res = await warmupData.get(`${compId}_${key}`);
  }
  if (!res) {
    res = await callback();
    if (isSSR) {
      warmupData.set(`${compId}_${key}`, res);
    }
  }
  return res;
};
